import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPersonCircleQuestion,
  faEarthEurope,
} from "@fortawesome/free-solid-svg-icons";
import CustomHook from "./CustomHook";

const Projects = () => {
  const [listProjects] = useState([
    {
      name: "Cynon Valley PC",
      des: "Cynon Valley PC is based in the Cynon Valley approximately 30 minutes from Cardiff.  An agency with Quality ServiceYou Can Trust will give your business the online presence it deserves.  This project was built using Wordpress and was designed from the ground up by myself.",
      mission: "Full Stack Developer",
      language: "Wordpress, PHP, HTML5, CSS3, JavaScript",
      images: "/CynonValleyPC.png",
    },
    {
      name: "Essential Events",
      des: "Essential Events is an events management company.  This project is to design and build an interactive web application which will allow Acts to register and offer their services.  Customers will be able to browse and search the Acts and booking the one they require.  Being built using Python Django and will launch when complete.",
      mission: "Full Stack Developer",
      language: "Python Django, HTML5, CSS3, JavaScript",
      images: "/EssentialEvents.png",
    },
    {
      name: "My Online Portfolio and CV",
      des: "My own personal Portfolio and CV site.  Designed and built using React JS to highlight my own personal skills and achievments.",
      mission: "Full Stack Developer",
      language: "React JS, HTML5, CSS3, JavaScript",
      images: "/StephenTucker.png",
    },
  ]);

  const refTab = useRef();
  const refDivs = useRef([]);
  CustomHook(refTab, refDivs);
  return (
    <section className="projects" ref={refTab}>
      <div className="title" ref={(el) => el && refDivs.current.push(el)}>
        These are my Projects
      </div>
      <div className="des" ref={(el) => el && refDivs.current.push(el)}>
        Since being made redundant I have pondered about using my skills to
        build interactive web applications for local businesses and beyond.
        Cynon Valley PC was born from this and I have since undertaken some
        projects as shown below.
      </div>
      <div className="list">
        {listProjects.map((value, key) => (
          <div key={key} className="item">
            <div
              className="images"
              ref={(el) => el && refDivs.current.push(el)}
            >
              <img src={value.images} alt={value.name} />
            </div>
            <div
              className="content"
              ref={(el) => el && refDivs.current.push(el)}
            >
              <h3>{value.name}</h3>
              <div className="des">{value.des}</div>
              <div className="mission">
                <div>
                  <FontAwesomeIcon icon={faPersonCircleQuestion} />
                </div>
                <div>
                  <h4>Mission</h4>
                  <div className="de">{value.mission}</div>
                </div>
              </div>
              <div className="mission">
                <div>
                  <FontAwesomeIcon icon={faEarthEurope} />
                </div>
                <div>
                  <h4>Languages</h4>
                  <div className="de">{value.language}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;

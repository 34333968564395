import React, { useRef, useState } from "react";
import CustomHook from "./CustomHook";

const Contacts = () => {
  const [listContacts] = useState([
    {
      title: "Mobile Number",
      value: "+44 7795803344",
      type: "tel",
    },
    {
      title: "Email Address",
      value: "steve@stephentucker.co.uk",
      type: "email",
    },
    {
      title: "Git Hub Repository",
      value: "https://github.com/UrKo2376",
      type: "url",
    },
    {
      title: "Linked In",
      value: "https://www.linkedin.com/in/steve-tucker-1368aa100",
      type: "url",
    },
  ]);
  const refTab = useRef();
  const divs = useRef([]);
  CustomHook(refTab, divs);

  const renderLink = (type, value) => {
    switch (type) {
      case "tel":
        return <a href={`tel:${value}`}>{value}</a>;
      case "email":
        return <a href={`mailto:${value}`}>{value}</a>;
      case "url":
        return (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        );
      default:
        return <span>{value}</span>;
    }
  };

  return (
    <section className="contacts" ref={refTab}>
      <div className="title" ref={(el) => el && divs.current.push(el)}>
        My Contact Details
      </div>
      <div className="des" ref={(el) => el && divs.current.push(el)}>
        Here you can find the variety of ways in which to contact me.
      </div>
      <div className="list" ref={(el) => el && divs.current.push(el)}>
        {listContacts.map((contact, key) => (
          <div key={key} className="item">
            <h3>{contact.title}</h3>
            <div className="contactLink">
              {renderLink(contact.type, contact.value)}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Contacts;

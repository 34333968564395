import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const CustomHook = (refTab = null, refDivs = null) => {
  const thisTab = refTab;
  const thisDivs = refDivs;
  const activeTab = useSelector((state) => state.activeTab);

  useEffect(() => {
    if (thisTab !== null && thisTab.current.classList.contains(activeTab)) {
      thisTab.current.scrollIntoView({ behavior: "smooth" });
    }

    if (thisDivs !== null) {
      thisDivs.current.forEach((div) => {
        div.classList.add("animation");
      });

      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;

        thisDivs.current.forEach((div) => {
          const rect = div.getBoundingClientRect();
          const elementTop = rect.top + scrollPosition;
          const elementBottom = rect.bottom + scrollPosition;

          // Check if the element is within the viewport
          if (
            (elementTop >= scrollPosition &&
              elementTop <= scrollPosition + windowHeight) || // Top is within the viewport
            (elementBottom >= scrollPosition &&
              elementBottom <= scrollPosition + windowHeight) || // Bottom is within the viewport
            (elementTop <= scrollPosition &&
              elementBottom >= scrollPosition + windowHeight) // Element is larger than viewport and encloses it
          ) {
            div.classList.add("active");
          } else {
            div.classList.remove("active");
          }
        });
      };

      window.addEventListener("scroll", handleScroll);

      // Call handleScroll immediately to check initial viewport state
      handleScroll();

      // Clean up the event listener on unmount
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [activeTab, thisTab, thisDivs]);
};

export default CustomHook;

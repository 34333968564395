import React, { useRef } from "react";
import CustomHook from "./CustomHook";

const Home = () => {
  const refTab = useRef();
  CustomHook(refTab);
  return (
    <section className="home" ref={refTab}>
      <div className="content">
        <div className="name">
          MY NAME IS <span>STEVE</span>
        </div>
        <div className="des">
          Hi, my name is Steve and I have been developing for over 30 years. I
          began my development journey way back in the 80's with Sinclair Basic
          on a Spectrum +2A and my interest grew and grew from there. Over the
          years I have honed my skills with various languages and technologies
          culminating in me opening my own development studio&nbsp;
          <a
            className="normalLink"
            href="https:\\www.cynonvalleypc.co.uk"
            target="_blank"
            rel="noreferrer"
          >
            Cynon Valley PC.
          </a>
        </div>
        <a
          className="buttonLink"
          href="/Stephen Tucker - Developer - CV.pdf"
          target="blank"
          rel="noopener noreferrer"
        >
          Download My CV
        </a>
      </div>
      <div className="avatar">
        <div className="card">
          <img
            src="/Steve Tucker Profile.jpg"
            alt="Steve at his wedding Sptember 2021"
          />
          <div className="info">
            <div>Developer</div>
            <div>British - Welsh</div>
            <div>04/80</div>
            <div>Male</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
